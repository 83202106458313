@use "common" as *;
@use "variables" as *;
@use "mixins" as *;

@keyframes jump {
    0% {
        transform: translate3d(0, 0, 0);
    }
    40% {
        transform: translate3d(0, 50%, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes rotated {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotatedHalf {
    0% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(90deg);
    }
    100% {
        transform: rotate(0);
    }
}

@keyframes rotatedHalfTwo {
    0% {
        transform: rotate(-90deg);
    }
    100% {
        transform: rotate(90deg);
    }
}

@keyframes scale-upOne {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.2);
    }
}

@keyframes scale-right {
    0% {
        transform: translateX(-50%);
    }
    50% {
        transform: translateX(50%);
    }
    100% {
        transform: translateX(-50%);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0.7;
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 0.7;
    }
}


@keyframes hvr-ripple-out {
    0% {
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 1;
    }
    100% {
        top: -6px;
        right: -6px;
        bottom: -6px;
        left: -6px;
        opacity: 0;
    }
}

@keyframes hvr-ripple-out-two {
    0% {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 1;
    }
    100% {
        top: -12px;
        right: -12px;
        bottom: -12px;
        left: -12px;
        opacity: 0;
    }
}

@keyframes scale-up-one {
    0% {

        transform: scale(1);
    }
    40% {

        transform: scale(0.5);
    }
    100% {

        transform: scale(1);
    }
}

@keyframes scale-up-two {
    0% {
        transform: scale(0.9);
    }
    40% {
        transform: scale(0.8);
    }
    100% {

        transform: scale(0.5);
    }
}

@keyframes scale-up-three {
    0% {
        transform: scale(0.7);
    }
    40% {
        transform: scale(0.4);
    }
    100% {
        transform: scale(0.7);
    }
}

@keyframes scale-up-four {
    0% {
        transform: scale(0.6);
    }
    40% {
        transform: scale(0.7);
    }
    100% {

        transform: scale(0.9);
    }
}

@keyframes scale-up-five {
    0% {
        transform: scale(1);
    }
    40% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(0.9);
    }
}


@keyframes animationFramesOne {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        transform: translate(73px, 1px) rotate(36deg);
    }
    40% {
        transform: translate(60px, 72px) rotate(72deg);
    }
    60% {
        transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
        transform: translate(40px, 72px) rotate(144deg);
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@keyframes animationFramesTwo {
    0% {
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }
    20% {
        transform: translate(73px, -1px) rotate(36deg) scale(0.9);
    }
    40% {
        transform: translate(141px, 72px) rotate(72deg) scale(1);
    }
    60% {
        transform: translate(83px, 122px) rotate(108deg) scale(1.2);
    }
    80% {
        transform: translate(40px, 72px) rotate(144deg) scale(1.1);
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }
}


@keyframes animationFramesThree {
    0% {
        transform: translate(135px, 90px);
    }
    50% {
        transform: translate(30px, 290px);
    }
    75% {
        transform: translate(80px, 190px);
    }
    100% {
        transform: translate(135px, 90px);
    }
}


@keyframes animationFramesFour {
    0% {
        transform: translate(30px, -70px) rotate(0deg);
    }
    50% {
        transform: translate(251px, 20px) rotate(180deg);
    }
    75% {
        transform: translate(30px, -70px) rotate(0deg);
    }
    100% {
        transform: translate(251px, 20px) rotate(180deg);
    }
}


@keyframes animationFramesFive {
    0% {
        transform: translate(61px, -99px) rotate(0deg);
    }
    21% {
        transform: translate(4px, -190px) rotate(38deg);
    }
    41% {
        transform: translate(-139px, -200px) rotate(74deg);
    }
    60% {
        transform: translate(-263px, -164px) rotate(108deg);
    }
    80% {
        transform: translate(-195px, -49px) rotate(144deg);
    }
    100% {
        transform: translate(-1px, 0px) rotate(180deg);
    }
}



@keyframes gradientBG {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 300% 0;
    }
    100% {
        background-position: 0 0;
    }
}

@keyframes imageBgAnim {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 120% 0;
    }
    100% {
        background-position: 0 0;
    }
}

/* / Bob / */

@keyframes float-bob-y {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(-20px);
    }
}

@keyframes float-bob-x {
    0% {
        transform: translateX(-50px);
    }
    50% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(-50px);
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 #ffffff;
    }
    50% {
        box-shadow: 0 0 0 30px rgba(1, 93, 199, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(1, 93, 199, 0);
    }
}

@keyframes pulse-blue {
    0% {
        box-shadow: 0 0 0 0 #2371ff;
    }
    50% {
        box-shadow: 0 0 0 30px rgba(1, 93, 199, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(1, 93, 199, 0);
    }
}

@keyframes pulse-orange {
    0% {
        box-shadow: 0 0 0 0 $theme-color;
    }
    50% {
        box-shadow: 0 0 0 30px rgba(1, 93, 199, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(1, 93, 199, 0);
    }
}

@keyframes pulse-grey {
    0% {
        box-shadow: 0 0 0 0 #ddd;
    }
    50% {
        box-shadow: 0 0 0 30px rgba(1, 93, 199, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(1, 93, 199, 0);
    }
}

/* custom  */
@keyframes team-shape-1 {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    25% {
        transform: translate(50px, -20px) rotate(0deg);
    }
    50% {
        transform: translate(100px, -40px) rotate(0deg);
    }
    75% {
        transform: translate(-50px, -70px) rotate(0deg);
    }
    100% {
        transform: translate(90px, 50px) rotate(0deg);
    }
}

@keyframes team-shape-3 {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    25% {
        transform: translate(-50px, 20px) rotate(0deg);
    }
    50% {
        transform: translate(-180px, 40px) rotate(0deg);
    }
    75% {
        transform: translate(-50px, -70px) rotate(0deg);
    }
    100% {
        transform: translate(90px, 50px) rotate(0deg);
    }
}

@keyframes wobble-vertical {
    16.65% {
        transform: translateY(8px);
    }
    33.3% {
        transform: translateY(-6px);
    }
    49.95% {
        transform: translateY(4px);
    }
    66.6% {
        transform: translateY(-2px);
    }
    83.25% {
        transform: translateY(1px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes animation-pulse-shrink {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.5);
    }
    75% {
        transform: scale(0.7);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes phone-icon-animate {
    0%,
    100% {
        transform: rotate(0)scale(1)skew(1deg);
    }
    10% {
        transform: rotate(-25deg)scale(1)skew(1deg);
    }
    20%,
    40% {
        transform: rotate(25deg)scale(1)skew(1deg);
    }
    30% {
        transform: rotate(-25deg)scale(1)skew(1deg);
    }
    50% {
        transform: rotate(0)scale(1)skew(1deg);
    }
}

/*--- preloader ---*/
.dark #preloader {
  background-color: #232323;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white-color;
  z-index: 999999;
}

.preloader {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0px;
  text-align: left;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
}

.preloader span {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: $theme-color;
  -webkit-animation: preloader 1.3s linear infinite;
  animation: preloader 1.3s linear infinite;
}

.preloader span:last-child {
  animation-delay: -0.8s;
  -webkit-animation-delay: -0.8s;
}

@keyframes preloader {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}





